import React from "react";

const PageHasBeenForceRefreshed = "page-has-been-force-refreshed";

/* The next two functions are guarded by a try catch as Chrome seems 
   to not always allow access to session storage :/ 
*/

const clearRetryFlag = () => {
    try {
        /* Clear retry flag from session storage */
        window.sessionStorage.removeItem(PageHasBeenForceRefreshed);
    } catch {
        /* Fall back to using localstorage */
        try {
            window.localStorage.removeItem(PageHasBeenForceRefreshed);
        } catch {
            /* If this fails we just give up :c */
            return;
        }
    }
};

const reloadPageOrReject = (reject) => {
    try {
        /* Check if retry flag is set */
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.sessionStorage.getItem(PageHasBeenForceRefreshed) || "false"
        );

        if (!pageHasAlreadyBeenForceRefreshed) {
            /* A refresh has not been tried yet, set the flag and reload the app */
            window.sessionStorage.setItem(PageHasBeenForceRefreshed, "true");
            return window.location.reload();
        }
    } catch {
        try {
            /* Fall back to using localstorage */
            const pageHasAlreadyBeenForceRefreshed = JSON.parse(
                window.localStorage.getItem(PageHasBeenForceRefreshed) ||
                    "false"
            );

            if (!pageHasAlreadyBeenForceRefreshed) {
                /* A refresh has not been tried yet, set the flag and reload the app */
                window.localStorage.setItem(PageHasBeenForceRefreshed, "true");
                return window.location.reload();
            }
        } catch {
            /* If this fails we just give up :c */
        }
    }
    /* A refresh has already been tried and did not solve the issue */
    reject();
};

const retry = (fn, retriesLeft, interval) => {
    return new Promise((resolve, reject) => {
        fn()
            .then((loadedModule) => {
                /* On a succefull import we clear the flag */
                clearRetryFlag();
                resolve(loadedModule);
            })
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        /* Out of retries, try a force refresh or accept it failed :/ */
                        reloadPageOrReject(() => reject(error));
                        return;
                    }
                    /* Retries left, try some more... */
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
};

export default function lazyRetry(
    importStatement,
    retries = 3,
    interval = 250
) {
    return React.lazy(() => retry(importStatement, retries, interval));
}
