import React, { useState } from "react";

const PopupContext = React.createContext({});

const PopupContextProvider = ({ children }) => {
    const [popup, setPopup] = useState(null);

    const closePopup = () => setPopup(() => null);
    const triggerPopup = (component) => setPopup(() => component);

    const context = {
        popup,
        triggerPopup,
        closePopup
    };

    return (
        <PopupContext.Provider value={context}>
            {children}
        </PopupContext.Provider>
    );
};

export { PopupContext, PopupContextProvider };
