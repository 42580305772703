import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import OtpInput from "react18-input-otp";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useQueryClient } from "@tanstack/react-query";

import {
    getUrlParams,
    removeTrafficUrlParams,
    updateUrlParameters,
} from "../../../core/common/util";
import { AppContext } from "../../../core/context/AppContext";
import authService from "../../../core/services/authService";
import TokenService from "../../../core/services/tokenService";
import FormInput from "../../common/formInput/Forminput";
import { useHistory } from "react-router-dom";

function LoginForm({ onTwitterClicked, nextUrl }) {
    const { register, handleSubmit } = useForm();
    const [errorMessage, setErrorMessage] = useState("");
    const [showOtpError, setShowOtpError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showOtpForm, setShowOtpForm] = useState(false);
    const [otp, setOtp] = useState("");
    const [formData, setFormData] = useState(null);
    const queryClient = useQueryClient();
    const history = useHistory();
    const { setLoginForm } = useContext(AppContext);
    const { t } = useTranslation(["public.login", "public.common"]);

    const handleChange = (enteredOtp) => {
        setOtp(enteredOtp);
    };
    const { pushInstruction } = useMatomo();

    const onSubmit = async (data) => {
        const response = await authService.login(
            data.username,
            data.password,
            otp,
            getUrlParams()
        );
        if (response.otp_error) {
            if (!showOtpForm) {
                setErrorMessage("");
                setShowOtpError(false);
                setShowOtpForm(true);
                setFormData(data);
                return;
            }
            setShowOtpError(() => true);
            return setTimeout(() => {
                setShowOtpError(false);
            }, 2000);
        }
        if (response.verify_error) {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set("verify", "true");
            updateUrlParameters(searchParams);
            setLoginForm("signup");
            return;
        }
        if (response.error) {
            setErrorMessage(response.error);
            return;
        }
        removeTrafficUrlParams(history);
        TokenService.setTokens(response.data);
        pushInstruction("setUserId", TokenService.getJwtData()?.user_uuid);
        queryClient.invalidateQueries(["userData"]);
        /*
          We could use this for a JS redirect but as some views are still in Django
          we need to do a "hard" redirect

          history.push(nextUrl);

        */
        if (nextUrl) {
            window.location.href = nextUrl;
        }
    };

    if (showOtpForm) {
        return (
            <div className="otp-form">
                <div className="otp-help">
                    {t("please-enter-the-2fa-token-from-your-token-generator")}
                </div>
                <div
                    className={
                        showOtpError
                            ? "otp-input-wrapper error"
                            : "otp-input-wrapper"
                    }
                >
                    <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={6}
                        separator={<span className="otp-spacer" />}
                        inputProps={{ minLength: 1 }}
                        onSubmit={() => onSubmit(formData)}
                        shouldAutoFocus={true}
                        isInputNum={true}
                    />
                </div>

                <button
                    className="submit-button"
                    onClick={() => onSubmit(formData)}
                >
                    {t("submit")}
                </button>

                <button
                    className="singup-button"
                    onClick={() => setShowOtpForm(false)}
                >
                    {t("go-back", { ns: "public.common" })}
                </button>
            </div>
        );
    }

    return (
        <form method="post" onSubmit={handleSubmit(onSubmit)}>
            <FormInput
                attributeObj={{
                    type: "field-wrapper",
                    name: "auth-username",
                    autoFocus: true,
                    autoCapitalize: "none",
                    autoComplete: "username",
                    maxLength: "254",
                    required: true,
                    id: "id_auth-username",
                    placeholder: t("email"),
                }}
                register={() => register("username")}
            />
            <FormInput
                attributeObj={{
                    type: showPassword ? "text" : "password",
                    name: "auth-password",
                    autoFocus: false,
                    autoCapitalize: "none",
                    autoComplete: "current-password",
                    maxLength: "254",
                    required: true,
                    id: "id_auth-password",
                    placeholder: t("password"),
                }}
                register={() => register("password")}
                withSpan={true}
                spanClassName={
                    showPassword
                        ? "button-icon pass-hide"
                        : "button-icon pass-hide close"
                }
                spanClose={() => setShowPassword((c) => !c)}
            />

            <div className="forgot-container">
                <span className="forgot" onClick={() => setLoginForm("reset")}>
                    {t("forgot-password?")}
                </span>
            </div>
            <div className="error-wrapper">
                {errorMessage && (
                    <div className="error-list">
                        <li key="error">{errorMessage}</li>
                    </div>
                )}
            </div>

            <button className="login-button">{t("login")}</button>
            <button
                className="singup-button"
                onClick={(e) => {
                    e.preventDefault();
                    setLoginForm("signup");
                }}
            >
                {t("create-account")}
            </button>

            <div className="separator"></div>

            <div className="bottom-container">
                <div
                    className="button-wrapper twitter-button"
                    onClick={onTwitterClicked}
                >
                    <span className="button-icon twitter"></span>
                    <span className="google-text">
                        {t("login-with-twitter")}
                    </span>
                </div>
            </div>
        </form>
    );
}

export default LoginForm;
