import React from "react";

const ProfileImage = ({ avatar }) => {
    if (!avatar) return <div className="profile-image" />;
    return (
        <div
            className="profile-image"
            data-sentry-block
            style={{ backgroundImage: `url('${avatar}')` }}
        />
    );
};

export default ProfileImage;
