import i18n from "../../internationalization";

import api from "./api";

export class AuthService {
    static instance = null;

    static getInstance() {
        if (!AuthService.instance) AuthService.instance = new AuthService();
        return AuthService.instance;
    }

    async login(email, password, otpToken, params) {
        const headers = i18n.language
            ? { "Accept-Language": i18n.language }
            : {};

        try {
            return await api.post(
                "/auth/login/",
                {
                    email,
                    password,
                    ...(otpToken && { otp_token: otpToken }),
                    ...(params && { params: params }),
                },
                {
                    headers,
                }
            );
        } catch (e) {
            let error;
            try {
                error = JSON.parse(e.request.response);
                if (error?.otp_token) {
                    return { otp_error: true };
                }
                if (error?.error?.[0] === "Please verify your email first") {
                    return { verify_error: true };
                }
                return { error: error.detail };
            } catch {
                error = String(e);
            }
            return { error };
        }
    }

    async passwordReset(email) {
        try {
            return await api.post("/auth/reset_password/", { email });
        } catch (e) {
            let error = "";
            try {
                error = JSON.parse(e.request.response).detail;
            } catch {
                error = String(e);
            }
            return { error };
        }
    }

    async signUp(email, password, passwordConfirmation, params) {
        const headers = i18n.language
            ? { "Accept-Language": i18n.language }
            : {};

        try {
            return await api.post(
                "/auth/signup/",
                {
                    email,
                    password,
                    confirm_password: passwordConfirmation,
                    ...(params && { params: params }),
                },
                {
                    headers,
                }
            );
        } catch (e) {
            let error = "";
            try {
                error = Object.values(JSON.parse(e.request.response)).flatMap(
                    (val) => val
                );
            } catch {
                error = String(e);
            }
            return { error };
        }
    }
}

export default AuthService.getInstance();
