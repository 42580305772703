import React, { memo } from "react";
import PropTypes from "prop-types";

import "./spinner.css";

/**
 *
 * @param {boolean} [inline=false] - If the spinner should be rendered in a span instead of a div
 * @param {string} [colorVar] - Can be used to overwrite the color. NOTE: Pass a CSS variable, ex "--f2f-secondary".
 * @param {number|string} [containerSize] - Can be used to overwrite the default size (2em)
 * @param {string|string} [spinnerWidth] - Can be used to overwrite the default size (0.3em)
 * @example
 *
 *     <Spinner color={"--f2f-secondary"}/>
 */

const Spinner = memo(({ inline, colorVar, containerSize, spinnerWidth }) => {
    const customStyle = {
        display: inline ? "inline" : "block",
        borderColor: colorVar ? `var(${colorVar})` : "var(--f2f-secondary)",
        borderWidth: spinnerWidth || "0.3em",
        borderTopColor: "transparent",
        height: containerSize || "2em",
        width: containerSize || "2em",
    };

    return <div className="f2f-spinner" style={customStyle} />;
});

Spinner.displayName = "Spinner";

Spinner.propTypes = {
    inline: PropTypes.bool,
    colorVar: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    containerSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    spinnerWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Spinner;
