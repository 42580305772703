import { isProd, minutesToMs } from "../common/util";
import { authEvents } from "../common/websocketEvents";

import TokenService from "./tokenService";

const disconnectTypes = {
    normalClosure: 1000,
    goingAway: 1001,
};

const pingMessage = JSON.stringify({ event: "ping", data: null });

class WebSocketService {
    static instance = null;

    static getInstance() {
        /* Makes sure there is only one instance of this service */
        if (!WebSocketService.instance)
            WebSocketService.instance = new WebSocketService();
        return WebSocketService.instance;
    }

    constructor() {
        this._url = this.getUrl();
        this._socket = null;
        this._callbacks = {};
        this._isRefreshingToken = false;
        this._keepAliveInterval = null;
        this._reconnectMs = 20;
        this._maxReconnectMs = 500;
        this._keepAliveMs = minutesToMs(1);
    }

    print(msg) {
        !isProd() && console.log(`[WebSocketService] ${msg}`);
    }

    isConnected() {
        if (this._socket && this._socket.readyState === WebSocket.OPEN)
            return true;
        return false;
    }

    getUrl() {
        return isProd()
            ? `wss://${window.location.hostname}/socket/`
            : "ws://localhost:8000/socket/";
    }

    connect() {
        if (
            this._socket &&
            (this._socket.readyState === WebSocket.OPEN ||
                this._socket.readyState === WebSocket.CONNECTING)
        ) {
            /* Socket is still open, no need to reconnect */
            return;
        }
        this._socket = new WebSocket(this._url);
        this._socket.onopen = () => {
            this.print("Socket opened");
            this.onConnect();
        };

        this._socket.onmessage = (e) => {
            this.onMessage(e.data);
        };

        this._socket.onerror = (e) => {
            this.print("error: " + JSON.stringify(e));
        };

        this._socket.onclose = (e) => {
            this.emit("socket.ondisconnect", null);

            clearInterval(this._keepAliveInterval);
            if (
                e.code !== disconnectTypes.normalClosure &&
                e.code !== disconnectTypes.goingAway
            ) {
                /* Reconnect if disconnect was not intentional */
                this.reconnect();
            }
        };
    }

    reconnect() {
        this.print(`Attempting to reconnect in ${this._reconnectMs}ms...`);
        setTimeout(() => this.connect(), this._reconnectMs);

        if (this._reconnectMs < this._maxReconnectMs) {
            this._reconnectMs += 20;
        } else {
            this._reconnectMs = this._maxReconnectMs;
        }
    }

    disconnect() {
        if (!this._socket) return;
        this.print("closing socket");
        this._socket.close();
    }

    on(events, callback) {
        /* Can be used to register event callbacks from components */
        if (!events || !callback) return;

        /* Allow for multiple event types */
        const eventArray = Array.isArray(events) ? events : [events];

        /* Iterate over the event array and register callbacks */
        eventArray.forEach((event) => {
            /* Either add to existing event type array or create new array */
            if (this._callbacks[event]) {
                this._callbacks[event].push(callback);
            } else {
                this._callbacks[event] = [callback];
            }
        });
    }

    off(events, callback) {
        /* Allows to remove registered event callbacks */
        if (!events || !callback) return;

        /* Allow for multiple event types */
        const eventArray = Array.isArray(events) ? events : [events];

        /* Iterate over the event array and unregister callbacks */
        eventArray.forEach((event) => {
            if (!this._callbacks[event]) return;
            /* Either remove this specific callback or delete the array */
            if (this._callbacks[event].length > 1) {
                this._callbacks[event] = this._callbacks[event].filter(
                    (cb) => cb !== callback
                );
            } else {
                delete this._callbacks[event];
            }
        });
    }

    emit(event, data) {
        /* Allows to emit events */
        if (Object.keys(this._callbacks).includes(event)) {
            this._callbacks[event].forEach((callback) => callback(data));
        }
    }

    send(data) {
        /* Send data to the remote server */
        try {
            this._socket.send(data);
            return true;
        } catch (e) {
            this.print(e.message);
            return false;
        }
    }

    authenticate() {
        /* Reads the current token and attempts to authenticate */
        if (!this._socket) return;
        const accessToken = TokenService.getAccessToken();
        if (accessToken) {
            this.send(
                JSON.stringify({
                    event: authEvents.authenticate,
                    data: { token: accessToken },
                })
            );
        }
    }

    handleTokenRefresh() {
        /* Attempts to get a new access token using the refresh token */
        this.print("Refreshing token");
        if (!this._socket) return;
        const refreshToken = TokenService.getRefreshToken();
        if (refreshToken) {
            this.send(
                JSON.stringify({
                    event: authEvents.refreshToken,
                    data: { refresh: refreshToken },
                })
            );
        }
        this._isRefreshingToken = true;
    }

    onRefreshSuccessful(data) {
        /* Called when a token refresh was successful */
        this.print("Refreshed tokens");
        TokenService.setTokens(data);
        this._isRefreshingToken = false;
    }

    onRefreshFailure() {
        /* Called when a token refresh failed */
        this.print("Failed to refresh tokens");
        TokenService.logout();
        this._isRefreshingToken = false;
    }

    onConnect() {
        /* Resets the reconnect interval, 
           fires on a connect event, 
           triggers auth message *
        */
        this._reconnectMs = 20;
        this.authenticate();
        this.emit("socket.onconnect", null);
        this._isRefreshingToken = false;
        this._keepAliveInterval = setInterval(
            () => this.send(pingMessage),
            this._keepAliveMs
        );
    }

    onMessage(message) {
        /* Passes the message to any registered callbacks*/
        this.print(`onMessage => ${message}`);
        const { event, data } = JSON.parse(message);

        if (event === authEvents.notAuthenticated && !this._isRefreshingToken) {
            return this.handleTokenRefresh();
        } else if (event === authEvents.refreshOk) {
            return this.onRefreshSuccessful(data);
        } else if (event === authEvents.refeshFail) {
            return this.onRefreshFailure();
        }

        this.emit(event, data);
    }

    waitForConnection(callback) {
        /* Can be used to wait until the socket connected */
        const socket = this._socket;
        const recurse = this.waitForConnection;
        setTimeout(() => {
            if (socket.readyState === 1) {
                this.print("Connection established");
                if (callback != null) callback();
                return;
            } else {
                this.print("Waiting for connection...");
                setTimeout(() => recurse(callback), 500);
            }
        }, 1);
    }

    state() {
        return this._socket.readyState;
    }
}

export default WebSocketService.getInstance();
