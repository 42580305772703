import React, { useEffect } from "react";
import { animated, useSpring } from "react-spring";

import LanguageList from "../languageList/LanguageList";

import "./language-selector.css";

function LanguageSelector({
    selectedLang,
    langListVisible,
    setLangListVisible,
    isLoginOpen,
    setSelectedLang,
    hasAnimation,
}) {
    const [whiteButtonStyle, animateWhiteButton] = useSpring(() => ({
        clipPath: "inset(0% 0% 0% 0% round 0%)",
    }));

    useEffect(() => {
        !isLoginOpen
            ? animateWhiteButton({
                  clipPath: "inset(0% 0% 0% 0% round 0%)",
                  config: { duration: 250 },
              })
            : animateWhiteButton({
                  clipPath: "inset(0% 100% 0% 0% round 0%)",
                  config: { duration: 320 },
              });
    }, [isLoginOpen]);

    return (
        <div className="language-selector">
            <div className="language-button-container black">
                <div
                    className="lang-select-button"
                    onClick={() => setLangListVisible((c) => !c)}
                >
                    <div className="lang-name">
                        <div className="global-icon"></div>
                        <div className="language-toggle">{selectedLang}</div>
                        <div
                            className={
                                langListVisible
                                    ? "icon-expand retract"
                                    : "icon-expand"
                            }
                        />
                    </div>
                </div>
            </div>
            {hasAnimation && (
                <animated.div className="fake-header" style={whiteButtonStyle}>
                    <div className="language-button-container white">
                        <div
                            className="lang-select-button"
                            onClick={() => setLangListVisible((c) => !c)}
                        >
                            <div className="lang-name">
                                <div className="global-icon"></div>
                                <div className="language-toggle">
                                    {selectedLang}
                                </div>
                                <div
                                    className={
                                        langListVisible
                                            ? "icon-expand retract"
                                            : "icon-expand"
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </animated.div>
            )}
            {langListVisible && (
                <LanguageList
                    closeLanguageList={() => setLangListVisible(false)}
                    selectedLang={selectedLang}
                    setSelectedLang={setSelectedLang}
                />
            )}
        </div>
    );
}

export default LanguageSelector;
