import React, {
    Suspense,
    useCallback,
    useContext,
    useEffect,
    useRef,
} from "react";

import { PopupContext } from "../../../core/context/PopupContext";
import SpinningLoadingIndicator from "../../common/spinningLoadingIndicator/SpinningLoadingIndicator";
import Handle from "../handler/Handler";

import "./popup-container.css";

const lock_scroll = () => {
    document.body.classList.add("lock-screen");
};

const unlock_scroll = () => {
    document.body.classList.remove("lock-screen");
};

function PopupContainer() {
    const { popup, closePopup } = useContext(PopupContext);
    const handleRef = useRef();
    const containerRef = useRef();

    const onClick = useCallback(
        (e) => {
            if (e.target.className === "popup-backdrop") {
                closePopup();
            }
        },
        [closePopup]
    );

    useEffect(() => {
        if (popup) {
            new Handle(handleRef.current, closePopup, containerRef.current);
            lock_scroll();
        } else {
            unlock_scroll();
        }
    }, [popup]);

    return (
        <div className="popup-container">
            {popup ? (
                <div className={"popup"} onClick={onClick}>
                    <div className="popup-backdrop" />
                    <div className="inner handle-container" ref={containerRef}>
                        <div className="handle" ref={handleRef}></div>
                        <div className="popup-padding">
                            <Suspense fallback={<SpinningLoadingIndicator />}>
                                {popup}
                            </Suspense>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default PopupContainer;
