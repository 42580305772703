import React from "react";
import { useHistory } from "react-router";

/**
 * 
 * @param {string} href - Where to link to 
 * @param {string} className - CSS class name to be used to style <a> tag
 * @param {boolean} [httpRedir] - If true, the tag will do an HTTP redirect
 * @param {boolean} [disabled] - If true, the children are rendered without the wrapping <a> tag
 * @param {callback} [onClick] - Optional extra callback to be executed before the redirect 
 * @param {object} [style] - Optional style object
 * @param {object} [redirState] - Optional state to pass on a JS redirect 
 */

export default function JsA({ href, className, children, httpRedir, disabled, onClick, style, redirState}) {
    const history = useHistory();

    const handleClick = (e) => {
        if (onClick) onClick();
        if (httpRedir) {
            console.log(`browser redirect to ${href}`);
            return;
        }
        e.preventDefault();
        history.push(href, redirState);
    };

    if (disabled) return children;

    return (
        <a href={href} className={className} onClick={handleClick} style={style}>
            {children}
        </a>
    );
}
