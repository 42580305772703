export const supportedLanguages = [
    { name: "English", key: "en", local_name: "English" },
    { name: "Spanish", key: "es", local_name: "Español" },
    { name: "German", key: "de", local_name: "Deutsch" },
    { name: "Dutch", key: "nl", local_name: "Nederlands" },
];

/**
 * @param {string} language
 */
export function getCurrentLanguageName(language){
    /* Strip off any country info */
    let lang_code = language?.split("-")?.[0];
    let supportedLang = supportedLanguages.find(lang => lang.key === lang_code);
    if (supportedLang){
        return supportedLang.local_name;
    }
    return "English";
};
