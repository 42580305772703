import { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import authService from "../../../core/services/authService";
import "../../../style/form.css";
import "./login-form.css";
import { AppContext } from "../../../core/context/AppContext";

const PasswordResetForm = () => {
    const { t } = useTranslation("public.login");
    const { register, handleSubmit } = useForm();
    const [errorMessage, setErrorMessage] = useState("");
    const [resetComplete, setResetComplete] = useState(false);
    const { setLoginForm } = useContext(AppContext);
    const onReset = async ({ username }) => {
        const response = await authService.passwordReset(username);
        if (response.error) {
            setErrorMessage(response.error);
            return;
        }
        setResetComplete(true);
    };

    if (resetComplete) {
        return (
            <Fragment key="after-reset">
                <div className="reset-text">{t("password-reset-message")}</div>
                <button
                    className="login-button"
                    onClick={(e) => {
                        e.preventDefault();
                        setResetComplete(false);
                        setLoginForm("login");
                    }}
                >
                    {t("back-to-login")}
                </button>
            </Fragment>
        );
    }
    return (
        <form method="post" onSubmit={handleSubmit(onReset)}>
            <input type="hidden" name="next" value="" />
            <input type="submit" hidden />
            <div className="error-wrapper">{errorMessage}</div>

            <div className="field-wrapper reset">
                <div className="field-core">
                    <input
                        type="email"
                        name="auth-username"
                        autoFocus
                        autoCapitalize="none"
                        autoComplete="username"
                        maxLength="254"
                        required
                        id="id_auth-username"
                        placeholder={t("email")}
                        {...register("username")}
                    />
                </div>
            </div>

            <button
                className="login-button"
            >{t("reset-password")}</button>
            <button
                className="singup-button"
                onClick={(e) => {
                    e.preventDefault();
                    setLoginForm("login");
                }}
            >
                {t("back-to-login")}
            </button>
        </form>
    );
};

export default PasswordResetForm;
