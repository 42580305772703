import React, { useState } from "react";

const MessengerContext = React.createContext({});

const MessengerContextProvider = ({
    children,
    initialChatUuid,
    initialMassMessageUuid,
}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [swiperMedia, setSwiperMedia] = useState(null);
    const [chatListOrdering, setChatListOrdering] = useState(
        "newest-unread-first"
    );
    const [chatUuid, setChatUuid] = useState(initialChatUuid);
    const [massMessageUuid, setMassMessageUuid] = useState(
        initialMassMessageUuid
    );
    const [fullscreenVideo, setFullscreenVideo] = useState(null);
    const [chatListFilters, setChatListFilters] = useState([]);

    const [query, setQuery] = useState("");
    const [massQuery, setMassQuery] = useState("");
    const [massMessagesFilter, setMassMessagesFilter] = useState("");

    const context = {
        chatUuid,
        setChatUuid,
        massMessageUuid,
        setMassMessageUuid,
        isPlaying,
        setIsPlaying,
        swiperMedia,
        setSwiperMedia,
        fullscreenVideo,
        setFullscreenVideo,
        chatListFilters,
        setChatListFilters,
        chatListOrdering,
        setChatListOrdering,
        query,
        setQuery,
        massQuery,
        setMassQuery,
        massMessagesFilter,
        setMassMessagesFilter,
    };

    return (
        <MessengerContext.Provider value={context}>
            {children}
        </MessengerContext.Provider>
    );
};

export { MessengerContext, MessengerContextProvider };
