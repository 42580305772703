import { useEffect } from "react";

import { setCssVariable } from "../../common/util";

export default function useUpdatePostHeight() {
    const updateHeight = () => {
        setCssVariable("--dynamic-viewport-height", `${window.innerHeight}px`);
    };

    useEffect(() => {
        if (CSS.supports("height", "100dvh")) return;
        updateHeight();
        window.addEventListener("resize", updateHeight);
        return () => {
            window.removeEventListener("resize", updateHeight);
        };
    }, []);
}
