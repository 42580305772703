import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import "./creator-badge.css";

const CreatorBadge = memo(({ badge }) => {
    const { t } = useTranslation("user.feed");
    switch (badge) {
        case "verified":
            return (
                <div
                    className="verified"
                    title={t("identified-and-verified-creator")}
                />
            );
        case "vip":
            return <div className="verified vip" title={t("vip-creator")} />;
        case "founder":
            return (
                <>
                    <div
                        className="verified founder"
                        title={t("f2f-founder")}
                    />
                    <span className="verified-text">{t("founder")}</span>
                </>
            );
        case "family":
            return (
                <>
                    <div className="verified family" title={t("f2f-family")} />
                    <span className="verified-text">{t("family")}</span>
                </>
            );
        case "studio":
            return (
                <>
                    <div className="verified studio" title={t("studio")} />
                    <span className="verified-text">{t("studio")}</span>
                </>
            );
        default:
            return null;
    }
});

export default CreatorBadge;
