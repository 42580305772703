import React, { Fragment, useContext, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { removeTrafficUrlParams } from "../../../core/common/util";
import { getUrlParams } from "../../../core/common/util";
import { AppContext } from "../../../core/context/AppContext";
import authService from "../../../core/services/authService";

function SignUpForm({ onTwitterClicked }) {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const passwordRef = useRef({});
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [validationErrors, setValidationErrors] = useState(null);
    const [registerComplete, setRegisterComplete] = useState(() => {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get("verify") ? true : false;
    });
    const history = useHistory();
    const { t } = useTranslation("public.login");
    const { setLoginForm } = useContext(AppContext);
    passwordRef.current = watch("password", "");

    const onRegistration = async ({
        username,
        password,
        passwordConfirmation,
    }) => {
        const response = await authService.signUp(
            username,
            password,
            passwordConfirmation,
            getUrlParams()
        );
        if (response.error) {
            if (Array.isArray(response.error)) {
                setValidationErrors(
                    response.error.map((error) => <li key={error}>{error}</li>)
                );
            }
            return;
        }
        removeTrafficUrlParams(history);
        setRegisterComplete(true);
    };

    const hasError = useMemo(() => {
        return errors.passwordConfirmation || validationErrors;
    }, [errors?.passwordConfirmation, validationErrors]);

    if (registerComplete) {
        return (
            <Fragment key="after-register">
                <div className="register-text">{t("verify-email-message")}</div>
                <button
                    className="login-button"
                    onClick={(e) => {
                        e.preventDefault();
                        setRegisterComplete(false);
                        setLoginForm("login");
                    }}
                >
                    {t("back-to-login")}
                </button>
            </Fragment>
        );
    }

    return (
        <form
            className="signup-form"
            method="post"
            onSubmit={handleSubmit(onRegistration)}
        >
            <div className="field-wrapper">
                <div className="field-core">
                    <input
                        type="email"
                        name="auth-username"
                        autoFocus
                        autoCapitalize="none"
                        autoComplete="username"
                        maxLength="254"
                        required
                        id="id_auth-username"
                        placeholder={t("email")}
                        {...register("username")}
                    />
                </div>
            </div>

            <div className="field-wrapper">
                <div className="field-core">
                    <input
                        type={showPassword ? "text" : "password"}
                        name="auth-password"
                        autoComplete="current-password"
                        required
                        id="id_auth-password"
                        placeholder={t("password")}
                        {...register("password")}
                    />

                    <span
                        className={
                            showPassword
                                ? "button-icon pass-hide"
                                : "button-icon pass-hide close"
                        }
                        onClick={() => setShowPassword((c) => !c)}
                    ></span>
                </div>
            </div>
            <div className="field-wrapper">
                <div className="field-core">
                    <input
                        type={showConfirmPassword ? "text" : "password"}
                        name="password_repeat"
                        autoComplete="current-password"
                        required
                        id="id_auth-password-confirm"
                        placeholder={t("password-confirmation")}
                        {...register("passwordConfirmation", {
                            validate: (value) =>
                                value === passwordRef.current ||
                                "The passwords do not match",
                        })}
                    />

                    <span
                        className={
                            showConfirmPassword
                                ? "button-icon pass-hide"
                                : "button-icon pass-hide close"
                        }
                        onClick={() => setShowConfirmPassword((c) => !c)}
                    ></span>
                </div>
            </div>

            {hasError && (
                <div className="field-error">
                    <ul className="error-list">
                        {errors.passwordConfirmation && (
                            <li key="pw-mismatch">
                                {errors.passwordConfirmation.message}
                            </li>
                        )}
                        {validationErrors}
                    </ul>
                </div>
            )}

            <button
                className={
                    hasError
                        ? "login-button create has-error"
                        : "login-button create"
                }
            >
                {t("create-account")}
            </button>

            <div className="separator"></div>

            <div className="bottom-container">
                <div
                    className="button-wrapper twitter-button"
                    onClick={onTwitterClicked}
                >
                    <span className="button-icon twitter"></span>
                    <span className="button-text">
                        {t("login-with-twitter")}
                    </span>
                </div>
            </div>

            <div className="separator"></div>

            <button
                className="have-account-button"
                onClick={(e) => {
                    e.preventDefault();
                    setLoginForm("login");
                }}
            >
                {t("i-already-have-an-account")}
            </button>
        </form>
    );
}

export default SignUpForm;
