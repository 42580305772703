import { createInstance } from "@datapunt/matomo-tracker-react";

import { isProd } from "./core/common/util";
import TokenService from "./core/services/tokenService";

export default createInstance({
    urlBase: "https://s.f2f.net/",
    siteId: 1,
    userId: TokenService.getJwtData()?.user_uuid,
    trackerUrl: "https://s.f2f.net/matomo.php", // optional, default value: `${urlBase}matomo.php`
    srcUrl: "https://s.f2f.net/matomo.js", // optional, default value: `${urlBase}matomo.js`
    disabled: !isProd(), // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: {
        // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 10, // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: {
        // optional, default value: {}
        // any valid matomo configuration, all below are optional
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: "POST",
    },
});
