import React, { useCallback, useContext } from "react";
import { useLocation } from "react-router-dom";
import ClickOutsideBoundary from "../../../core/boundaries/ClickOutsideBoundary";
import { AppContext } from "../../../core/context/AppContext";
import useIsDesktop from "../../../core/hooks/common/useIsDesktop";
import { supportedLanguages } from "../../../core/internationalization/supportedLanguages";
import i18n from "../../../internationalization";

function LanguageList({ closeLanguageList, selectedLang, setSelectedLang }) {
    const isDesktop = useIsDesktop();
    const { isLoginOpen } = useContext(AppContext);
    const location = useLocation();
    const onClickOutsideLangList = (e) => {
        /* Ignore clicks on the toggle to prevent duplicate state updates */
        if (e.target.closest(".language-button-container")) return;
        closeLanguageList();
    };
    const getLanguageClassName = useCallback(
        (lang) => (selectedLang === lang ? "active" : ""),
        [selectedLang]
    );

    return (
        <ClickOutsideBoundary
            onClickOutside={onClickOutsideLangList}
            style={{
                position: "fixed",
                zIndex: 2,
                right: isDesktop ? "30px" : "15px",
                top: "50px",
            }}
        >
            <div
                className={`lang-list-wrapper visible ${
                    isLoginOpen || !(location.pathname === "/") ? "black" : ""
                }`}
            >
                <ul className="lang-select-list">
                    {supportedLanguages.map((lang) => (
                        <li
                            key={lang.key}
                            className={getLanguageClassName(lang.local_name)}
                            onClick={() => {
                                setSelectedLang(lang.local_name);
                                i18n.changeLanguage(lang.key);
                            }}
                        >
                            {lang.local_name}
                        </li>
                    ))}
                </ul>
            </div>
        </ClickOutsideBoundary>
    );
}

export default LanguageList;
