import { useEffect } from "react";

/**
 * Hook for firing a callback when the user clicks outside of an element
 *
 * @param {ref} ref - Ref to the element 
 * @param {callback} callback - Called when clicked outside of the element
 *
 * @example
 *          
 *   function Example() {
 *       const divRef = useRef(null);
 *       useClickOutside(divRef, () => console.log('Clicked outside!'));
 *       return <div ref={divRef};
 *    }
 */

const useClickOutside = (ref, callback) => {
    useEffect(() => {
        function onClickOutside(event) {
            if (ref?.current && !ref.current.contains(event.target)) {
                callback(event);
            }
        }
        document.addEventListener("mousedown", onClickOutside, false);
        return () =>
            document.removeEventListener("mousedown", onClickOutside, false);
    }, [ref, callback]);
};

export default useClickOutside;
