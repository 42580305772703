import { useHistory } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { getUrlParams, removeTrafficUrlParams } from "../../common/util";
import api from "../../services/api";
import TokenService from "../../services/tokenService";

export default function useSendParams() {
    const history = useHistory();
    return useQuery(
        ["urlParams"],
        async () => {
            const params = getUrlParams();
            if (TokenService.isAuthenticated() && params) {
                await api.post("/users/source/", { params });
                removeTrafficUrlParams(history);
            }
            return params;
        },
        {
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );
}
