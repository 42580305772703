import React, { useCallback, useContext } from "react";
import { useLocation } from "react-router-dom";

import { ReactComponent as AddIcon } from "../../../assets/navBarIcons/add_menu.svg";
import { ReactComponent as AddGreyIcon } from "../../../assets/navBarIcons/add_menu-grey.svg";
import { ReactComponent as ExploreIcon } from "../../../assets/navBarIcons/explore.svg";
import { ReactComponent as ExploreGreyIcon } from "../../../assets/navBarIcons/explore-grey.svg";
import { ReactComponent as HomeIcon } from "../../../assets/navBarIcons/home.svg";
import { ReactComponent as HomeGreyIcon } from "../../../assets/navBarIcons/home-grey.svg";
import { ReactComponent as MessengerIcon } from "../../../assets/navBarIcons/messenger.svg";
import { ReactComponent as MessengerUnreadIcon } from "../../../assets/navBarIcons/messenger_unread_dark.svg";
import { ReactComponent as MessengerUnreadGreyIcon } from "../../../assets/navBarIcons/messenger_unread_grey.svg";
import { ReactComponent as MessengerGreyIcon } from "../../../assets/navBarIcons/messenger-grey.svg";
import { uuidv4Regex } from "../../../core/common/util";
import { AppContext } from "../../../core/context/AppContext";
import useIsProd from "../../../core/hooks/common/useIsProd";
import { useWaffleFlag } from "../../../core/hooks/common/useWaffleFlag";
import JsA from "../JsA/JsA";

import ProfileImage from "./ProfileImage";

import "./bottom-nav.css";

export default function BottomNav({ toggleMenu, menuOpen, userData }) {
    const location = useLocation();
    const isProd = useIsProd();
    const { isFullScreen } = useContext(AppContext);
    const messengerEnabled = useWaffleFlag("enable-react-messenger");

    const activeExplore = useCallback(() => {
        let notExplorePages = ["/messenger/", "/notifications/", "/accounts/"];
        if (
            notExplorePages.some((page) =>
                location.pathname.startsWith(page)
            ) ||
            !location.pathname.match(new RegExp("/.+"))
        )
            return false;
        return true;
    }, [location.pathname]);

    /* Hide in fullscreen mode */
    if (isFullScreen) return null;

    /* Hide if not authenticated */
    if (!userData) return null;

    /* Hide if in messenger detailview */
    if (location?.pathname?.startsWith("/messenger/")) {
        const subUrl = location.pathname.split("/messenger/")[1];
        const uuidPart = subUrl.split("/")[0];
        if (uuidv4Regex.test(uuidPart)) {
            return null;
        }
    }

    const pageState = (page_url) => {
        return location.pathname === page_url ? true : false;
    };

    const closeSettingsIfOpen = () => menuOpen && toggleMenu();

    return (
        <nav className="bottomnav">
            <div className="bottomnav-inner">
                <JsA
                    href="/"
                    onClick={closeSettingsIfOpen}
                    className={"menu-item home-icon"}
                    httpRedir={pageState("/") === "active"}
                >
                    {pageState("/") ? (
                        <HomeIcon data-testid={"home-icon"} />
                    ) : (
                        <HomeGreyIcon />
                    )}
                </JsA>
                <JsA
                    href="/explore/"
                    onClick={closeSettingsIfOpen}
                    className={"menu-item explore-icon"}
                >
                    {activeExplore() ? <ExploreIcon /> : <ExploreGreyIcon />}
                </JsA>
                {userData.creator && (
                    <JsA
                        href="/accounts/posts/new/"
                        onClick={closeSettingsIfOpen}
                        className={"menu-item add-icon"}
                        httpRedir={isProd}
                    >
                        {pageState("/accounts/posts/new/") ? (
                            <AddIcon />
                        ) : (
                            <AddGreyIcon />
                        )}
                    </JsA>
                )}
                <div className="messages-container">
                    <JsA
                        href="/messenger/"
                        onClick={closeSettingsIfOpen}
                        className={"menu-item messenger-icon"}
                        httpRedir={isProd && !messengerEnabled}
                    >
                        {pageState("/messenger/") ? (
                            userData?.unread_messages ? (
                                <MessengerUnreadIcon
                                    data-testid={"unread-messages"}
                                />
                            ) : (
                                <MessengerIcon />
                            )
                        ) : userData?.unread_messages ? (
                            <MessengerUnreadGreyIcon
                                data-testid={"unread-messages"}
                            />
                        ) : (
                            <MessengerGreyIcon />
                        )}
                    </JsA>
                </div>
                <span
                    className="menu-item toggle-slider-nav profile"
                    onClick={toggleMenu}
                >
                    <ProfileImage avatar={userData?.avatar} />
                </span>
            </div>
            <div className="toolbar-overflow">
                <img className="icon" alt="icon" />
            </div>
        </nav>
    );
}
