import webSocketService from "../services/webSocketService";

export const authEvents = {
    authenticate: "auth.authenticate",
    notAuthenticated: "auth.not_authenticated",
    refreshToken: "auth.refresh_token",
    refreshOk: "auth.refresh_token.success",
    refeshFail: "auth.refresh_token.error",
};

export const paymentEvents = {
    created: "payment.created",
    status: "payment.status",
};

export const userEvents = {
    online: "user.online",
    offline: "user.offline",
};

export const invalidationEvents = {
    messenger: "invalidate.messenger",
};

export const chatEvents = {
    message: "chat.message",
    messageUpdate: "chat.message.update",
    typingStart: "chat.typing.start",
    typingEnd: "chat.typing.end",
    read: "chat.read",
};

export const sendSocketEvent = (event, data) => {
    if (webSocketService.isConnected()) {
        return webSocketService.send(
            JSON.stringify({
                event,
                data,
            })
        );
    }
    return false;
};
