import React, { useState } from "react";

const NotificationContext = React.createContext({});

const NotificationContextProvider = (props) => {
    const [notifications, setNotifications] = useState([]);

    const triggerNotification = (notificationText, isError) => {
        const timestamp = Date.now();
        setNotifications(cur => [...cur, { text: notificationText, timestamp, isError}]);
        setTimeout(() => setNotifications(cur => cur.filter(i => i.timestamp !== timestamp)), 4500);
    };
    const context = {
        notifications,
        triggerNotification,
    };

    return (
        <NotificationContext.Provider value={context}>
            {props.children}
        </NotificationContext.Provider>
    );
};

export { NotificationContext, NotificationContextProvider };
