import { initReactI18next } from "react-i18next";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

/* Import dayjs locales and APIs */
import "dayjs/locale/nl";
import "dayjs/locale/en-gb";
import "dayjs/locale/de";
import "dayjs/locale/es";
import "dayjs/locale/uk";

import { supportedLanguages } from "./core/internationalization/supportedLanguages";
/* extend dayjs with additional APIs */
dayjs
    .extend(calendar)
    .extend(relativeTime)
    .extend(localizedFormat)
    .extend(duration)
    .extend(localeData)
    .extend(customParseFormat);

/* Configurate i18n */
i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        supportedLngs: supportedLanguages.map((lang) => lang.key),
        ns: [],
        defaultNS: "public.common",
        load: "languageOnly", // to only load 'en' if browserlanguage is set to en-US or en-GB
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        detection: {
            order: ["localStorage", "navigator"],
            caches: ["localStorage"],
        },
        returnEmptyString: false,
    });

i18n.on("languageChanged", (lang) => dayjs.locale(lang));

export default i18n;
