import React, { useEffect, useRef } from "react";

import Handle from "../../popups/handler/Handler";

function HandlerContainer({
    popupName,
    Component,
    hide,
    visible,
    nomargin,
    componentProps,
}) {
    const container = useRef();
    const handle = useRef();
    const popup = useRef();

    const lock_scroll = () => {
        document.body.classList.add("lock-screen");
    };

    const unlock_scroll = () => {
        document.body.classList.remove("lock-screen");
    };

    const close = () => {
        unlock_scroll();
        hide && hide();
    };

    const on_click = (e) => {
        if ((e.target.classList + "").includes("popup ")) {
            close();
        }
    };

    useEffect(() => {
        if (visible) {
            lock_scroll();
        } else {
            unlock_scroll();
        }
    }, [visible]);

    useEffect(() => {
        visible && new Handle(handle.current, close, container.current);
    }, [visible]);

    return visible ? (
        <div className={"popup " + popupName} ref={popup} onClick={on_click}>
            <div
                className={`inner handle-container ${
                    nomargin ? "nomargin" : ""
                }`}
                ref={container}
            >
                <div className="handle" ref={handle}></div>

                <Component componentProps={componentProps} />
            </div>
        </div>
    ) : null;
}

export default HandlerContainer;
