import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { MatomoProvider } from "@datapunt/matomo-tracker-react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { ErrorBoundary } from "./core/common/ErrorBoundary";
import { history } from "./core/common/history";
import { isProd } from "./core/common/util";
import { AppContextProvider } from "./core/context/AppContext";
import { NotificationContextProvider } from "./core/context/NotificationContext";
import { PopupContextProvider } from "./core/context/PopupContext";
import queryClient from "./core/services/queryClient";
import App from "./App";
import MatomoInstance from "./MatomoInstance";
import { sentryBlacklist } from "./sentryBlacklist";

import "./index.css";
import "./style/base.css";
import "./style/themes/standard.css";
import "./style/themes/fanatise.css";

Sentry.init({
    dsn: "https://d7351330a8f84b7b999cbd1bd9517b80@o4503958683516928.ingest.sentry.io/4503958687907840",
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        new BrowserTracing({
            routingInstrumentation:
                Sentry.reactRouterV5Instrumentation(history),
        }),
        new Sentry.Replay(),
    ],
    allowUrls: ["f2f.com"],
    /* Reduce traceSampleRate on production */
    tracesSampleRate: isProd() ? 0.1 : 1.0,
    /* Only enable Sentry on production */
    enabled: isProd(),
    ignoreErrors: sentryBlacklist,
    release: process.env.REACT_APP_BUILD_VERSION || "non-release",
});

ReactDOM.render(
    <React.StrictMode>
        <MatomoProvider value={MatomoInstance}>
            <ErrorBoundary>
                <QueryClientProvider client={queryClient}>
                    <NotificationContextProvider>
                        <PopupContextProvider>
                            <Router history={history}>
                                <AppContextProvider>
                                    <App />
                                </AppContextProvider>
                            </Router>
                        </PopupContextProvider>
                    </NotificationContextProvider>
                </QueryClientProvider>
            </ErrorBoundary>
        </MatomoProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
