import axios from "axios";
import { getApiUrl, handleDjangoMiddlewareResponse, isProd } from "../common/util";
import TokenService from "./tokenService";

const axiosInstance = axios.create({
    baseURL: getApiUrl(),
    headers: {
        "Content-Type": "application/json",
    },
});

let isRefreshing = false;
let requestQueue = [];

const processQueue = (error = null, token = null) => {
    requestQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    requestQueue = [];
};

export const getTokenFromCookieOrLogout = async (resolve, reject) => {
    try {
        isRefreshing = true;

        if (isProd() === false) {
            TokenService.logout();
        } else {
            if ((await TokenService.cookieToToken()) === false) {
                TokenService.logout();
                isRefreshing = false;
                throw Error("Could not refresh");
            }
        }
        isRefreshing = false;
        resolve();
    } catch {
        isRefreshing = false;
        reject();
    }
};



const shouldIntercept = (e) =>
    e?.response?.status === 401 &&
    e?.config?.url !== "/auth/login/" &&
    e?.config?.url !== "/auth/refresh_token/";

/* Add access token to requests by default */
axiosInstance.interceptors.request.use(
    (config) => {
        const accessToken = TokenService.getAccessToken();
        if (accessToken) {
            config.headers["Authorization"] = "Bearer " + accessToken;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/* Attempts to refresh JWT on 401 or get a new one from the API on a 400 */
axiosInstance.interceptors.response.use(
    (res) => res,
    async (error) => {
        if (handleDjangoMiddlewareResponse(error)) {
            return Promise.reject(error);
        }
        if (!shouldIntercept(error)) {
            return Promise.reject(error);
        }

        if (error.config._retry || error.config._queued) {
            return Promise.reject(error);
        }

        const originalConfig = error.config;

        /* If we are already refreshing, we add our request to the queue */
        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                requestQueue.push({ resolve, reject });
            })
                .then(() => {
                    originalConfig._queued = true;
                    return axiosInstance(originalConfig);
                })
                .catch(() => {
                    /* Return original error */
                    return Promise.reject(error);
                });
        }

        const token = TokenService.getRefreshToken();

        originalConfig._retry = true;

        if (token) {
            /* Attempt to refresh token */
            isRefreshing = true;
            return new Promise((resolve, reject) =>
                axiosInstance
                    .post("/auth/refresh_token/", {
                        refresh: token,
                    })
                    .then(({ data }) => {
                        TokenService.setTokens({
                            access: data.access,
                            refresh: data.refresh,
                        });
                        processQueue(null, data.access);
                        resolve(axiosInstance(originalConfig));
                    })
                    .catch(async (err) => {
                        TokenService.logout();
                        reject(err);
                        processQueue(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    })
            );
        } else {
            /* We don't have a refresh token so we should just log out */
            TokenService.logout();
        }
    }
);

export default axiosInstance;
