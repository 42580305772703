import React, { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import JsA from "../../../components/common/JsA/JsA";
import { AppContext } from "../../../core/context/AppContext";
import useIsDesktop from "../../../core/hooks/common/useIsDesktop";
import { getCurrentLanguageName } from "../../../core/internationalization/supportedLanguages";
import i18n from "../../../internationalization";
import LanguageSelector from "../languageSelector/LanguageSelector";
import LanguageSelectorMobile from "../languageSelector/LanguageSelectorMobile";

import "./unauthenticated-header.css";

export default function UnauthenticatedHeader() {
    const isDesktop = useIsDesktop();
    const location = useLocation();

    const { t } = useTranslation(["public.login"]);
    const [selectedLang, setSelectedLang] = useState(
        getCurrentLanguageName(i18n.language)
    );
    const { setLoginForm, setIsLoginOpen, isLoginOpen } =
        useContext(AppContext);
    const [langListVisible, setLangListVisible] = useState(false);

    const openRegister = () => {
        setLoginForm("signup");
        setIsLoginOpen(true);
    };
    const openLogin = () => {
        setLoginForm("login");
        setIsLoginOpen(true);
    };

    const isOnLanding = location.pathname === "/";

    if (!isOnLanding && !isDesktop) return null;

    return (
        <>
            <div
                className={
                    isOnLanding
                        ? "unauthenticated-header-background"
                        : "unauthenticated-header-background visible"
                }
            />
            <div
                className={`unauthenticated-desktop-header-container${
                    isDesktop ? "" : " mobile"
                }`}
            >
                <JsA
                    href="/"
                    httpRedir={true}
                    className={isOnLanding ? "f2f-logo" : "f2f-logo purple"}
                ></JsA>
                {isDesktop && (
                    <Fragment key="header-buttons">
                        <div
                            className={
                                isLoginOpen
                                    ? "navbar-item-button has-border hide"
                                    : `navbar-item-button has-border  ${
                                          isOnLanding ? "" : "black"
                                      }`
                            }
                            onClick={() => openRegister()}
                        >
                            {t("create-account", { ns: "public.login" })}
                        </div>
                        <div
                            className={
                                isLoginOpen
                                    ? "navbar-item-button hide"
                                    : `navbar-item-button ${
                                          isOnLanding ? "" : "black"
                                      }`
                            }
                            onClick={() => openLogin()}
                        >
                            {t("login", { ns: "public.login" })}
                        </div>
                    </Fragment>
                )}

                {isDesktop ? (
                    <LanguageSelector
                        selectedLang={selectedLang}
                        langListVisible={langListVisible}
                        setLangListVisible={setLangListVisible}
                        isLoginOpen={isLoginOpen}
                        setSelectedLang={setSelectedLang}
                        hasAnimation={isOnLanding}
                    />
                ) : (
                    <LanguageSelectorMobile
                        selectedLang={selectedLang}
                        langListVisible={langListVisible}
                        setLangListVisible={setLangListVisible}
                        setSelectedLang={setSelectedLang}
                        isOnLanding={isOnLanding}
                    />
                )}
            </div>
        </>
    );
}
