import React, { Suspense } from "react";
import ErrorPage from "../../pages/ErrorPage";
import * as Sentry from "@sentry/react";

export function ErrorBoundary({ children }) {
    return (
        <Sentry.ErrorBoundary
            fallback={() => (
                <Suspense fallback={<div />}>
                    <ErrorPage />
                </Suspense>
            )}
        >
            {children}
        </Sentry.ErrorBoundary>
    );
}
