import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useIsProd from "../../../core/hooks/common/useIsProd";
import { useWaffleFlag } from "../../../core/hooks/common/useWaffleFlag";
import TokenService from "../../../core/services/tokenService";
import HandlerContainer from "../../common/handlerContainer/HandlerContainer";
import JsA from "../../common/JsA/JsA";
import CreatorBadge from "../../creator/creatorBadge/CreatorBadge";

import "./user-menu.css";

function UserMenu({ visible, hide, userData }) {
    const isCreator = !!userData?.creator;
    const messengerEnabled = useWaffleFlag("enable-react-messenger");
    const agencyEnabled = userData?.waffle_flags?.includes(
        "enable-agency-environment"
    );

    const isProd = useIsProd();

    const { t } = useTranslation([
        "user.settings",
        "public.common",
        "creator.settings",
    ]);
    const onLogout = () => {
        TokenService.clearLocalStorage();
        if (isProd) {
            window.location.replace("/accounts/logout/");
        } else {
            TokenService.logout();
        }
    };

    const renderEarningsStatistics = useMemo(() => {
        return (
            <>
                <JsA
                    href="/accounts/earnings/"
                    onClick={hide}
                    className="earnings"
                    httpRedir={isProd}
                >
                    {t("earnings", {
                        ns: "user.settings",
                    })}
                </JsA>

                <JsA href="/statistics/" onClick={hide} className="statistics">
                    {t("statistics", {
                        ns: "public.common",
                    })}
                </JsA>
            </>
        );
    }, [isProd, hide, t]);

    const agencyLink = useMemo(() => {
        if (!agencyEnabled) return;
        return isCreator ? (
            userData?.creator?.agency ? (
                <div className="group">
                    <JsA
                        href="/agency/dashboard"
                        onClick={hide}
                        className="agency"
                        httpRedir={isProd}
                    >
                        {t("navigate-to-agency", {
                            ns: "user.settings",
                        })}
                    </JsA>
                </div>
            ) : null
        ) : userData?.agency ? (
            <div className="group">
                <JsA
                    href="/agency/"
                    onClick={hide}
                    className="agency"
                    httpRedir={isProd}
                >
                    {t("navigate-to-agency", {
                        ns: "user.settings",
                    })}
                </JsA>
            </div>
        ) : null;
    }, [
        agencyEnabled,
        isCreator,
        userData?.creator?.agency,
        userData?.agency,
        isProd,
        hide,
        t,
    ]);

    const agencyInviteLink = useMemo(() => {
        if (!agencyEnabled) return;
        return isCreator ? (
            userData?.creator?.agency_invites ? (
                <JsA
                    href="/agency/invites/c"
                    onClick={hide}
                    className="agency-invites"
                    httpRedir={isProd}
                >
                    {t("agency-invites", {
                        ns: "user.settings",
                    })}
                </JsA>
            ) : null
        ) : userData?.agency_invites ? (
            <JsA
                href="/agency/invites/u"
                onClick={hide}
                className="agency-invites"
                httpRedir={isProd}
            >
                {t("agency-invites", {
                    ns: "user.settings",
                })}
            </JsA>
        ) : null;
    }, [
        agencyEnabled,
        isCreator,
        userData?.creator?.agency_invites,
        userData?.agency_invites,
        isProd,
        hide,
        t,
    ]);

    const Component = () =>
        visible ? (
            <nav className="slider-nav">
                <div className="creator">
                    <JsA
                        href={
                            isCreator
                                ? `/${userData?.username}/`
                                : "/accounts/account/edit/"
                        }
                        onClick={hide}
                        className="profile-image"
                        data-sentry-block
                        style={
                            userData?.avatar
                                ? {
                                      backgroundImage: `url('${userData.avatar}')`,
                                  }
                                : {}
                        }
                        httpRedir={isProd}
                    />
                    <div className="info">
                        <div className="info-inner">
                            <div className="flex">
                                <div className="title">
                                    {userData.display_name}
                                </div>
                                <CreatorBadge
                                    badge={userData?.verification_badge}
                                />
                            </div>
                            {isCreator ? (
                                <>
                                    <JsA
                                        href={`/${userData?.username}/`}
                                        className="username"
                                        onClick={hide}
                                    >
                                        @{userData?.username}
                                    </JsA>
                                    {userData && (
                                        <div className="fans-followers">
                                            <JsA
                                                href="/accounts/users?usertype=fans"
                                                httpRedir={isProd}
                                                onClick={hide}
                                            >
                                                {userData?.creator?.fans}{" "}
                                                {t("fans", {
                                                    ns: "public.common",
                                                })}
                                            </JsA>
                                            &nbsp;<span>&#183;</span>&nbsp;
                                            <JsA
                                                href="/accounts/users?usertype=followers"
                                                httpRedir={isProd}
                                                onClick={hide}
                                            >
                                                {userData?.creator?.followers}{" "}
                                                {t("followers", {
                                                    ns: "public.common",
                                                })}
                                            </JsA>
                                        </div>
                                    )}
                                </>
                            ) : null}
                            <div className="title"></div>
                        </div>
                    </div>
                </div>

                <div className="list">
                    <div className="group">
                        <JsA
                            href="/notifications/"
                            onClick={hide}
                            className={
                                userData?.notifications
                                    ? "notifications active"
                                    : "notifications"
                            }
                            httpRedir={isProd}
                        >
                            {t("notifications", {
                                ns: "user.settings",
                            })}
                        </JsA>
                        {agencyInviteLink}
                        {userData?.payment_required ? (
                            <JsA
                                href="/accounts/payment-required/"
                                onClick={hide}
                                className="noincasso"
                                httpRedir={isProd}
                            >
                                {t("payment-required", {
                                    ns: "user.settings",
                                })}
                            </JsA>
                        ) : null}
                    </div>
                    {isCreator && (
                        <div className="group">
                            {renderEarningsStatistics}
                            <JsA
                                href="/accounts/plans/list/"
                                onClick={hide}
                                className="plans"
                                httpRedir={isProd}
                            >
                                {t("subscription-plans", {
                                    ns: "user.settings",
                                })}
                            </JsA>
                            <JsA
                                href="/accounts/campaigns/"
                                onClick={hide}
                                className="discounts"
                                httpRedir={isProd}
                            >
                                {t("campaigns", {
                                    ns: "creator.settings",
                                })}
                            </JsA>
                            <JsA
                                href="/accounts/automatedcampaigns/"
                                onClick={hide}
                                className="campaigns"
                                httpRedir={isProd}
                            >
                                {t("automated-messages", {
                                    ns: "creator.settings",
                                })}
                            </JsA>
                            <JsA
                                href="/accounts/media/library"
                                onClick={hide}
                                className="media"
                                httpRedir={isProd}
                            >
                                {t("media-library", {
                                    ns: "creator.settings",
                                })}
                            </JsA>
                            {userData?.creator?.has_finished_import ===
                            false ? (
                                <JsA
                                    href="/accounts/import/"
                                    onClick={hide}
                                    className="import"
                                    httpRedir={isProd}
                                >
                                    {t("import-posts", {
                                        ns: "creator.settings",
                                    })}
                                </JsA>
                            ) : null}
                        </div>
                    )}
                    <div className="group">
                        <JsA
                            href="/accounts/post-collections/"
                            onClick={hide}
                            className="paid-content"
                            httpRedir={isProd}
                        >
                            {t("saved-or-paid-posts", {
                                ns: "user.settings",
                            })}
                        </JsA>
                        <JsA
                            href="/accounts/subscriptions/"
                            onClick={hide}
                            className="subscriptions"
                            httpRedir={isProd}
                        >
                            {t("subscriptions", {
                                ns: "user.settings",
                            })}
                        </JsA>
                        <JsA
                            href="/accounts/following/"
                            onClick={hide}
                            className="following"
                            httpRedir={isProd}
                        >
                            {t("following", { ns: "public.common" })}
                        </JsA>
                    </div>
                    <div className="group">
                        <JsA
                            href="/accounts/paymentmethods/"
                            onClick={hide}
                            className="payment-methods"
                            httpRedir={isProd}
                        >
                            {t("payment-methods", {
                                ns: "user.settings",
                            })}
                        </JsA>
                        {isCreator ? (
                            userData?.creator?.onboarding_complete === false ? (
                                <JsA
                                    href="accounts/creator/verification-status/"
                                    onClick={hide}
                                    className="become"
                                    httpRedir={isProd}
                                >
                                    {t("creator-verification-status", {
                                        ns: "creator.settings",
                                    })}
                                </JsA>
                            ) : null
                        ) : userData?.agency ? null : (
                            <JsA
                                href="/accounts/creator/become/"
                                onClick={hide}
                                className="become"
                                httpRedir={isProd}
                            >
                                {t("become-a-creator", {
                                    ns: "user.settings",
                                })}
                            </JsA>
                        )}
                        <JsA
                            href="https://about.f2f.com/help/"
                            onClick={hide}
                            className="help"
                            httpRedir={isProd}
                        >
                            {t(messengerEnabled ? "faq" : "help-and-support", {
                                ns: "user.settings",
                            })}
                        </JsA>

                        {messengerEnabled && (
                            <JsA
                                href="https://f2f.com/messenger/f2f-support/"
                                onClick={hide}
                                className="support-chat"
                                httpRedir={isProd}
                            >
                                {t("support-chat", { ns: "user.settings" })}
                            </JsA>
                        )}
                        <JsA
                            href="/accounts/settings"
                            onClick={hide}
                            className="settings"
                            httpRedir={isProd}
                        >
                            {t("settings", { ns: "user.settings" })}
                        </JsA>
                    </div>
                    {agencyLink}
                    <div className="group">
                        <a
                            href="/accounts/logout/"
                            className="logout"
                            onClick={(e) => {
                                e.preventDefault();
                                onLogout();
                                document
                                    .getElementsByTagName("body")[0]
                                    .classList.remove("lock-screen");
                                hide();
                            }}
                        >
                            {t("logout", { ns: "user.settings" })}
                        </a>
                    </div>
                </div>
            </nav>
        ) : null;

    if (!userData) return null;

    return (
        <HandlerContainer
            popupName="UserMenu"
            Component={Component}
            hide={hide}
            visible={visible}
        />
    );
}

export default UserMenu;
