import React, { useRef } from "react";
import useClickOutside from "../hooks/common/useClickOutside";
import PropTypes from "prop-types";

/**
 * Boundary detecting clicks outside of itself
 *
 * @param {callback} callback - Called when clicked outside of the boundary
 *
 * @example
 *
 *   <ClickOutsideBoundary onClickOutside={() => console.log("click!")}>
 *       <div className="some-class">
 *           <div className="some-other-class"/>
 *       </div>
 *   </ClickOutsideBoundary>
 */

function ClickOutsideBoundary({ onClickOutside, children, style }) {
    const ref = useRef(null);
    useClickOutside(ref, onClickOutside);
    return (
        <div ref={ref} style={style}>
            {children}
        </div>
    );
}

ClickOutsideBoundary.propTypes = {
    onClickOutside: PropTypes.func,
    children: PropTypes.node,
    style: PropTypes.object,
};

export default ClickOutsideBoundary;
