import React from "react";
import Spinner from "../spinner/Spinner";
import "./spinning-loading-indicator.css";

/**
 *
 * @param {string|React.ReactElement} [loadingText] - Text to be displayed while loading, can also be a component
 * @param {object} [style] - Optional custom style for the text part
 * @param {object} [spinnerStyle] - Optional props to pass to the Spinner component
 *
 */

export default function SpinningLoadingIndicator({
    loadingText,
    style,
    spinnerStyle,
}) {
    let spinnerProps = spinnerStyle
        ? {
              containerSize: 22,
              spinnerWidth: 2,
              colorVar: "--grey",
              ...spinnerStyle,
          }
        : {
              containerSize: 22,
              spinnerWidth: 2,
              colorVar: "--grey",
          };

    return (
        <div className="spinning-loading-indicator">
            <Spinner {...spinnerProps} />
            <div className="type-wrapper" style={style}>
                {loadingText || null}
            </div>
        </div>
    );
}
