import React, { Suspense, useContext, useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import AuthenticatedHeader from "./components/common/AuthenticatedHeader/AuthenticatedHeader";
import BottomNav from "./components/common/bottomNav/BottomNav";
import LoadingIndicatorLogo from "./components/common/loadingIndicator/LoadingIndicatorLogo";
import NotificationContainer from "./components/common/notificationContainer/NotificationContainer";
import UnauthenticatedHeader from "./components/common/unauthenticatedHeader/UnauthenticatedHeader";
import AuthenticationDrawer from "./components/landing/login/AuthenticationDrawer";
import PopupContainer from "./components/popups/popupContainer/PopupContainer";
import UserMenu from "./components/settings/userMenu/UserMenu";
import lazyRetry from "./core/common/lazyRetry";
import { isProd } from "./core/common/util";
import { AppContext } from "./core/context/AppContext";
import { MessengerContextProvider } from "./core/context/MessengerContext";
import { PopupContext } from "./core/context/PopupContext";
import useIsDesktop from "./core/hooks/common/useIsDesktop";
import useSendParams from "./core/hooks/common/useSendParams";
import useUpdatePostHeight from "./core/hooks/common/useUpdatePostHeight";
import useUserData from "./core/hooks/common/useUserData";
import { useWaffleFlag } from "./core/hooks/common/useWaffleFlag";
import WebsocketService from "./core/services/webSocketService";

const Messenger = lazyRetry(() => import("./pages/Messenger"));
const Home = lazyRetry(() => import("./pages/Home"));
const Account = lazyRetry(() => import("./pages/Account"));
const Explore = lazyRetry(() => import("./pages/Explore"));
const Notifications = lazyRetry(() => import("./pages/Notifications"));
const Statistics = lazyRetry(() => import("./pages/Statistics"));
const NotFound = lazyRetry(() => import("./pages/NotFound"));
const Creator = lazyRetry(() => import("./pages/Creator"));

export default function App() {
    const { closePopup } = useContext(PopupContext);
    const { setIsFullScreen } = useContext(AppContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const isDesktop = useIsDesktop();
    const location = useLocation();
    const { data: userData, isLoading, isError } = useUserData();
    const messengerEnabled = useWaffleFlag("enable-react-messenger");

    const toggleMenu = () => setMenuOpen((cur) => !cur);

    useSendParams();
    useUpdatePostHeight();
    /* Close any open popups and disable fullscreen on a route change */
    useEffect(() => {
        if (location.pathname.endsWith("/overview/")) return;
        closePopup();
        setIsFullScreen(false);
    }, [location.pathname]);

    useEffect(() => {
        const onAuth = () => console.log("Websocket authenticated!");
        WebsocketService.on("auth.success", onAuth);
        return () => WebsocketService.off("auth.success", onAuth);
    }, []);

    if (isLoading && !isError) {
        return null; //  loading icon is handled by the root:empty
    }
    return (
        <>
            <NotificationContainer />
            <PopupContainer />
            <Suspense fallback={<div />}>
                <AuthenticationDrawer userData={userData} />
            </Suspense>
            <Suspense fallback={<div />}>
                {userData ? (
                    isDesktop && (
                        <AuthenticatedHeader
                            toggleMenu={toggleMenu}
                            userData={userData}
                        />
                    )
                ) : (
                    <UnauthenticatedHeader />
                )}
            </Suspense>
            <Suspense fallback={<LoadingIndicatorLogo />}>
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() => <Home userData={userData} />}
                        public
                        data-testid="home-route"
                    />
                    <Route
                        exact
                        path={["/explore/", "/explore/search/"]}
                        render={() => <Explore userData={userData} />}
                        private
                        data-testid="explore-route"
                    />
                    {(!isProd() || messengerEnabled) && (
                        <Route
                            exact
                            path={[
                                "/messenger/",
                                "/messenger/broadcast/",
                                "/messenger/broadcast/:massMessageUuid/",
                                "/messenger/broadcast/:massMessageUuid/overview/",
                                "/messenger/:chatUuid/",
                                "/messenger/:chatUuid/overview/",
                            ]}
                            render={({ match }) => (
                                <MessengerContextProvider
                                    initialChatUuid={match?.params?.chatUuid}
                                    initialMassMessageUuid={
                                        match?.params?.massMessageUuid
                                    }
                                >
                                    <Messenger />
                                </MessengerContextProvider>
                            )}
                            private
                            data-testid="messenger-route"
                        />
                    )}
                    {!isProd() && (
                        <Route
                            exact
                            path="/notifications/"
                            component={Notifications}
                            private
                            data-testid="notifications-route"
                        />
                    )}
                    {!isProd() && (
                        <Route
                            path="/accounts/"
                            component={Account}
                            private
                            data-testid="accounts-route"
                        />
                    )}
                    <Route
                        path="/statistics/"
                        render={() => <Statistics userData={userData} />}
                        private
                        data-testid="statistics-route"
                    />
                    <Route
                        path="/:username([\w.-]+)/"
                        render={({ match, location }) => (
                            <Creator
                                match={match}
                                location={location}
                                userData={userData}
                            />
                        )}
                        public
                        data-testid="creator-route"
                    />
                    <Route component={NotFound} public />
                </Switch>
            </Suspense>
            <Suspense fallback={<div />}>
                <BottomNav
                    toggleMenu={toggleMenu}
                    menuOpen={menuOpen}
                    userData={userData}
                />
            </Suspense>
            <Suspense fallback={<div />}>
                <UserMenu
                    visible={menuOpen}
                    hide={toggleMenu}
                    userData={userData}
                />
            </Suspense>
        </>
    );
}
