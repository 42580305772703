import React, { Suspense, useContext } from "react";
import "./NotificationContainer.css";
import { NotificationContext } from "../../../core/context/NotificationContext";
import SpinningLoadingIndicator from "../spinningLoadingIndicator/SpinningLoadingIndicator";
import { useLocation } from "react-router-dom";
import useIsDesktop from "../../../core/hooks/common/useIsDesktop";

function NotificationContainer() {
    const { notifications } = useContext(NotificationContext);
    const location = useLocation();
    const isDesktop = useIsDesktop();
    const isStatistics = location?.pathname?.startsWith("/statistics/");

    return (
        <div
            className={
                isStatistics && !isDesktop
                    ? "toast-container top-margin"
                    : "toast-container"
            }
        >
            {notifications.map((notification) => (
                <div
                    className={
                        "toast-messages " +
                        (notification.isError ? "error " : "success ") +
                        (isStatistics && !isDesktop ? "top-margin" : "")
                    }
                    key={`toast-${notification.timestamp}`}
                >
                    <div className="inner">
                        <Suspense fallback={<SpinningLoadingIndicator />}>
                            {notification.text}
                        </Suspense>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default NotificationContainer;
