import React, { useContext } from "react";
import useIsProd from "../../../core/hooks/common/useIsProd";
import JsA from "../JsA/JsA";
import "./authenticated-header.css";
import "../button/button.css";
import LogoSvg from "../../../assets/f2f-purple-minimal.svg";
import { AppContext } from "../../../core/context/AppContext";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useWaffleFlag } from "../../../core/hooks/common/useWaffleFlag";

function AuthenticatedHeader({ toggleMenu, userData }) {
    const isProd = useIsProd();
    const { isFullScreen } = useContext(AppContext);
    const { t } = useTranslation([
        "user.feed",
        "public.common",
        "creator.post",
    ]);
    const location = useLocation();
    const messengerEnabled = useWaffleFlag("enable-react-messenger");

    if (isFullScreen) return null;

    return (
        <nav className="navbar" data-testid="header-navbar-with-user">
            <div className="navbar-inner">
                <JsA className="navbar-item" href="/" httpRedir={true}>
                    <img className="navbar-logo" src={LogoSvg} alt="f2f-logo" />
                </JsA>

                <div className="right">
                    <JsA
                        href="/"
                        className={`navbar-item ${
                            location.pathname === "/" ? "active" : "inactive"
                        }`}
                        httpRedir={location.pathname === "/"}
                    >
                        {t("feed", { ns: "user.feed" })}
                    </JsA>
                    <JsA
                        href="/explore/"
                        className={`navbar-item ${
                            location.pathname === "/explore/"
                                ? "active"
                                : "inactive"
                        }`}
                    >
                        {t("explore", { ns: "public.common" })}
                    </JsA>
                    <JsA
                        href="/messenger/"
                        className={`navbar-item ${
                            location.pathname === "/messenger/"
                                ? "active"
                                : "inactive"
                        }`}
                        httpRedir={isProd && !messengerEnabled}
                    >
                        {t("messenger", { ns: "public.common" })}
                        {userData?.unread_messages ? (
                            <span className="unread-indicator"></span>
                        ) : null}
                    </JsA>
                    {userData.creator && (
                        <>
                            <JsA
                                href="/accounts/posts/new/"
                                className="navbar-item button auto-width"
                                httpRedir={isProd}
                            >
                                {t("create-post", { ns: "creator.post" })}
                            </JsA>
                            <JsA
                                href="/accounts/media/library/add/"
                                className="navbar-item button auto-width"
                                httpRedir={isProd}
                            >
                                {t("upload-media", { ns: "creator.post" })}
                            </JsA>
                        </>
                    )}
                    <div
                        className="navbar-item toggle-slider-nav account"
                        onClick={toggleMenu}
                    >
                        <div
                            className="profile-image"
                            data-sentry-block
                            style={
                                userData.avatar
                                    ? {
                                          backgroundImage: `url('${userData.avatar}')`,
                                      }
                                    : {}
                            }
                        ></div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default AuthenticatedHeader;
