import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import api from "./api";
import { history } from "../common/history";
import { isProd, isPrivateRoute, isDjangoMiddlewareResponse } from "../common/util";
import WebSocketService from "./webSocketService";
import queryClient from "./queryClient";

export class TokenService {
    static instance = null;

    static getInstance() {
        if (!TokenService.instance) TokenService.instance = new TokenService();
        return TokenService.instance;
    }

    getTokens() {
        try {
            return JSON.parse(localStorage.getItem("tokens"));
        } catch {
            return {};
        }
    }

    getRefreshToken() {
        return this.getTokens()?.refresh;
    }

    getAccessToken() {
        return this.getTokens()?.access;
    }

    async cookieToToken() {
        /* Tries to get a JWT by sending an authenticated request to the /api/token/init endpoint. */
        let response;
        try {
            response = await api.get("/token/init/", {
                withCredentials: true,
            });
            if (response.status === 200 && response.data) {
                /* Update the tokens and user object */
                this.setTokens(response.data);
                return true;
            }
        } catch (e) {
            if (isDjangoMiddlewareResponse(e.response)) {
                console.log("/token/init/ middleware redirect");
                return true;
            }
        }

        return false;
    }


    isAuthenticated() {
        try {
            const { access, refresh } = this.getTokens();
            const tokens = {
                access: jwt_decode(access),
                refresh: jwt_decode(refresh),
            };
            return (
                tokens.refresh.exp > dayjs().unix() ||
                tokens.access.exp > dayjs().unix()
            );
        } catch {
            return false;
        }
    }

    isExplorePreferenceSet() {
        return localStorage.getItem("preferencesSet");
    }

    setExplorePreferencesSet() {
        localStorage.setItem("preferencesSet", true);
    }

    setTokens(tokens) {
        localStorage.setItem("tokens", JSON.stringify(tokens));
        /* Trigger WebSocket auth */
        WebSocketService.authenticate();
    }

    clearLocalStorage() {
        localStorage.removeItem("tokens");
        localStorage.removeItem("preferencesSet");
        localStorage.removeItem("shownFeedShortcuts");
    }

    getJwtData() {
        try {
            const tokens = this.getTokens();
            return jwt_decode(tokens.access);
        } catch (e) {
            return null;
        }
    }

    logout() {
        this.clearLocalStorage();
        const curPath = window.location.pathname;
        /* Skip redirects on public pages */
        WebSocketService.disconnect();
        if (!isPrivateRoute(curPath)) return;
        if (curPath.includes("/feed/")) {
            return history.push(curPath.split("/feed/")[0] + "/");
        }
        if (isProd()) {
            window.location.replace("/accounts/logout/");
        } else {
            queryClient.clear();
            history.push("/");
        }
    }
}

export default TokenService.getInstance();
