import dayjs from "dayjs";

export function isIos() {
    const iosPlatforms = ["iPad", "iPhone", "iPod"];

    return (
        iosPlatforms.includes(window.navigator.platform) ||
        (window.navigator.userAgent.includes("Mac") && "ontouched" in document)
    );
}

export function toRelativePostTime(postTime, t) {
    let now = dayjs();
    let postDateTime = dayjs(postTime);
    /* Check if post is yet to be released */
    if (now.isBefore(postDateTime))
        return t("scheduled", { ns: "public.time" });
    /* Check if post was released just now (less than 5 mins ago) */
    let minutesAgo = now.diff(postDateTime, "minutes");
    if (minutesAgo < 5) return t("just-now", { ns: "public.time" });
    /* Specify amount of minutes ago (if less than an hour ago) */
    if (minutesAgo < 60)
        return t("x-minutes-ago", {
            ns: "public.time",
            minutesAgo: minutesAgo,
        });
    /* Specify amount of hours (if less than 24 hours ago) */
    if (minutesAgo < 1440)
        return t("x-hours-ago", {
            ns: "public.time",
            hoursAgo: Math.floor(minutesAgo / 60),
        });
    /* Specify yesterday if less than 2 days ago */
    if (minutesAgo < 2880) return t("yesterday", { ns: "public.time" });
    /* Specify day of the week if less than a week ago */
    if (minutesAgo < 10080) return postDateTime.format("dddd");

    return postDateTime.format("D MMMM YYYY");
}

/**
 * Gets the message time in various formats based on the current date and time.
 *
 * @export
 * @function getMessageTime
 * @param {string|number|Date|dayjs.Dayjs} dateTime - The date and time of the message.
 * @param {function} t - A translation function for internationalization.
 * @param {boolean} isPreview - If the result will be rendered in a preview.
 * @returns {string} Formatted message time.
 */
export function getMessageTime(dateTime, t, isPreview) {
    let now = dayjs();
    let messageTime = dayjs(dateTime);
    if (messageTime.isSame(now, "day")) {
        return isPreview
            ? messageTime.format("LT")
            : t("today", { ns: "public.time" });
    } else if (messageTime.isSame(now.subtract(1, "day"), "day")) {
        return t("yesterday", { ns: "public.time" });
    } else if (messageTime.isAfter(now.subtract(7, "day"), "day")) {
        return messageTime.format("dddd");
    } else {
        return messageTime.format(isPreview ? "L" : "LL");
    }
}

export function getApiUrl() {
    const envUrl = process.env.REACT_APP_API_URL;
    return envUrl ? envUrl : "http://127.0.0.1:8000/api";
}

export function isProd() {
    return !!process.env.REACT_APP_PROD_BUILD;
}

export function formatMSS(s) {
    return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
}

export function getRandomFromArray(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
}

export function getCssVariable(variableName) {
    return getComputedStyle(document.documentElement).getPropertyValue(
        variableName
    );
}

export function getHexColor(colorVariable, hexOpacity) {
    const color = getComputedStyle(document.documentElement).getPropertyValue(
        colorVariable
    );
    return hexOpacity ? color + hexOpacity : color;
}

export function setCssVariable(variableName, variableValue) {
    document.documentElement.style.setProperty(variableName, variableValue);
}

export function minutesToMs(minutes) {
    return minutes * (60 * 1000);
}

export function detectMouseWheelDirection(e) {
    return e.nativeEvent.wheelDelta > 0 ? "up" : "down";
}

export function isYOverflowing(element) {
    return element.scrollHeight > element.clientHeight;
}

export function isPrivateRoute(pathname) {
    /* Check for known protected paths */
    const protectedRoutes = [
        "/messenger",
        "/explore",
        "/statistics",
        "/accounts",
        "/notifications",
        /* TODO: remove next ones: */
        "/chat-detail/",
        "/mass-message-detail-view/",
    ];
    /* We use startsWith to also catch nested paths */
    if (protectedRoutes.some((route) => pathname.startsWith(route)))
        return true;

    /* We also check if we are on a creator feed */
    if (pathname.includes("/feed/")) return true;

    /* If we get here we are on a creator profile or non existent page */
    return false;
}

export function isDjangoMiddlewareResponse(res) {
    return (
        res?.status === 403 &&
        res?.headers?.["content-type"] &&
        res?.headers?.["content-type"] === "application/json" &&
        res?.data?.redirect_to
    );
}

export function handleDjangoMiddlewareResponse(error) {
    /* Detects responses from middleware within the Django project */
    if (isDjangoMiddlewareResponse(error?.response)) {
        console.log(
            `Middleware redirect to ${error.response.data.redirect_to}`
        );
        window.location.href = error.response.data.redirect_to;
        return true;
    }
    return false;
}

export function vwToPixels(value) {
    return (window.innerWidth * value) / 100;
}

export function vwToPixelString(value) {
    return `${vwToPixels(value)}px`;
}

/**
 * Pads or truncates an array to force a specific desired length
 *
 * @param {Array} arr
 * @param {Number} desiredLength
 */
export function padOrTruncateArray(arr, desiredLength) {
    const currentLength = arr.length;

    if (currentLength < desiredLength) {
        /* Array too short, pad with the start of the original array */
        const padCount = desiredLength - currentLength;
        let padding = [];
        for (let i = 0; i < padCount; i++) {
            padding.push(arr[i % currentLength]);
        }
        arr = arr.concat(padding);
    } else if (currentLength > desiredLength) {
        /* Array too long, truncate it */
        arr.length = desiredLength;
    }
    return arr;
}

export function removeSurroundingWhitespace(str) {
    /* Removes any trailing and or leading whitespace */
    const regex = /^\s+|\s+$/g;

    return str.replace(regex, "");
}

export function getStaticFolder() {
    return process.env.PUBLIC_URL + "/static/" || "/static/";
}

/**
 * @param {URLSearchParams} urlParams
 */
export function updateUrlParameters(urlParams) {
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.replaceState(null, "", newUrl);
}

export function getUrlParams() {
    const urlParams = new URLSearchParams(location.search);
    const params = {};
    for (const [key, value] of urlParams.entries()) {
        params[key] = value;
    }
    return Object.keys(params).length > 0 ? params : null;
}

export function removeTrafficUrlParams(history) {
    const urlParams = new URLSearchParams(location.search);
    let params = [];
    for (const [key] of urlParams.entries()) {
        if (key.startsWith("utm_")) {
            params.push(key);
        }
    }
    for (const paramKey of params) {
        urlParams.delete(paramKey);
    }
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    history.replace(newUrl);
}

/**
 *
 * @param {Array} data
 * @param {number} itemsPerRow
 * @returns {Array}
 */
export function splitArrayIntoRows(data, itemsPerRow) {
    const rows = [];
    const numRows = Math.ceil(data.length / itemsPerRow);

    for (let i = 0; i < numRows; i++) {
        const rowStartIndex = i * itemsPerRow;
        const rowEndIndex = rowStartIndex + itemsPerRow;
        const row = data.slice(rowStartIndex, rowEndIndex);
        rows.push(row);
    }

    return rows;
}

/**
 * @param {string} pageParam
 * @returns {string}
 */
export function extractCursorPaginator(pageParam) {
    /* Extract cursor from next URL */
    let cursor = "";
    if (pageParam) {
        const url = new URL(pageParam);
        const urlParams = new URLSearchParams(url.search);
        cursor = urlParams.get("cursor");
    }

    /* Construct new URL params */
    const params = new URLSearchParams();
    if (cursor) params.append("cursor", cursor);
    return params.toString();
}

export const uuidv4Regex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[4][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

/**
 * Fetches all queries from the queryCache that match the provided query key.
 *
 * @function
 * @param {string} queryKey - The query key to match in the queryCache.
 * @param {QueryClient} queryClient - The QueryClient instance to fetch the cache from.
 * @returns {Array} An array of queryKeys that match the provided queryKey.
 *
 * @example
 * const noteQueries = getQueries('notes', queryClient);
 */
export const getQueries = (queryKey, queryClient) => {
    const queryCache = queryClient.getQueryCache();
    const matchingQueries = [];

    queryCache?.queries?.forEach((query) => {
        if (query.queryKey[0] === queryKey) {
            matchingQueries.push(query.queryKey);
        }
    });

    return matchingQueries;
};
