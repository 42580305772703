import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { animated, useSpring } from "react-spring";

import { updateUrlParameters } from "../../../core/common/util";
import { AppContext } from "../../../core/context/AppContext";
import { NotificationContext } from "../../../core/context/NotificationContext";

import LoginForm from "./LoginForm";
import PasswordResetForm from "./PasswordResetForm";
import SignUpForm from "./SignUpForm";

import "../../../style/form.css";
import "./login-form.css";

export default function AuthenticationDrawer({ userData }) {
    const { t } = useTranslation([
        "public.login",
        "public.common",
        "public.error",
    ]);
    const { triggerNotification } = useContext(NotificationContext);
    const [isOpen, setIsOpen] = useState(false);

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const nextUrl = urlParams?.get("next");

    const { loginForm, setIsLoginOpen, setLoginForm, isLoginOpen } =
        useContext(AppContext);

    const onTwitterClicked = useCallback(() => {
        window.location.href = "/accounts/oauth/twitter/";
    }, []);

    const [authDrawerStyle, animateAuthDrawerStyle] = useSpring(() => ({
        translateX: "100%",
    }));
    const [shadowBackgroundStyle, animateShadowBackgroundStyle] = useSpring(
        () => ({
            opacity: 0,
            pointerEvents: "none",
        })
    );
    const formTitle = useMemo(() => {
        if (loginForm === "login") return t("login");
        if (loginForm === "reset") return t("reset-password");
        if (loginForm === "signup") return t("create-account");
        return "";
    }, [loginForm, t]);

    const openForm = (immediate) => {
        setIsOpen(true);
        const animationDuration = 250;
        animateAuthDrawerStyle({
            translateX: "0%",
            config: { duration: animationDuration },
            immediate: !!immediate,
        });

        animateShadowBackgroundStyle({
            opacity: 0.56,
            pointerEvents: "all",
            config: { duration: animationDuration },
            delay: animationDuration,
            immediate: !!immediate,
        });
    };
    const closeForm = () => {
        setIsLoginOpen(false);

        const params = new URLSearchParams(location.search);
        params?.delete("action");
        params?.delete("next");
        updateUrlParameters(params);

        const animationDuration = 320;

        animateAuthDrawerStyle({
            translateX: "100%",
            config: { duration: animationDuration },
            onResolve: () => setIsOpen(false),
        });

        animateShadowBackgroundStyle({
            opacity: 0,
            pointerEvents: "none",
            config: { duration: animationDuration },
        });
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const actionParam = params?.get("action");
        const referralCode = params?.get("referral");
        const nextParam = params?.get("next");
        const errorParam = params?.get("error");

        if (errorParam) {
            triggerNotification(t(errorParam, { ns: "public.error" }), true);
            params?.delete("error");
            updateUrlParameters(params);
        }

        if (actionParam === "login" || nextParam) {
            /* If action is login or next param is present open login form */
            setLoginForm("login");
            setIsLoginOpen(true);
            openForm(true);
        } else if (actionParam === "signup") {
            /* If action is signup open signup form */
            setLoginForm("signup");
            setIsLoginOpen(true);
            openForm(true);
        } else if (referralCode) {
            /* If a referralCode is present open the signup form */
            setLoginForm("signup");
            setIsLoginOpen(true);
            openForm(true);
            return;
        }
    }, [location.search]);

    useEffect(() => {
        isLoginOpen && openForm();
    }, [isLoginOpen]);

    useEffect(() => {
        userData?.username && isLoginOpen && closeForm();
    }, [userData?.username, isLoginOpen]);

    return (
        <>
            <animated.div
                className="shadow-background"
                onClick={() => closeForm()}
                style={shadowBackgroundStyle}
            />
            {isOpen && (
                <animated.div
                    className={"landing-form-container"}
                    style={authDrawerStyle}
                >
                    <div className="close-button" onClick={() => closeForm()} />
                    <div className="login-form-container">
                        <div className="login-title">{formTitle}</div>

                        {loginForm === "login" && (
                            <LoginForm
                                onTwitterClicked={onTwitterClicked}
                                nextUrl={nextUrl}
                            />
                        )}

                        {loginForm === "reset" && <PasswordResetForm />}
                        {loginForm === "signup" && (
                            <SignUpForm onTwitterClicked={onTwitterClicked} />
                        )}
                    </div>
                </animated.div>
            )}
        </>
    );
}
