import React from "react";

function FormInput({ attributeObj, register, withSpan, spanClassName, spanClose }) {
    return (
        <div className="field-wrapper">
            <div className="field-core">
                <input
                    type={attributeObj.type}
                    name={attributeObj.name}
                    autoFocus={attributeObj.autoFocus}
                    autoCapitalize={attributeObj.autoCapitalize}
                    autoComplete={attributeObj.autoComplete}
                    maxLength={attributeObj.maxLength}
                    required={attributeObj.required}
                    id={attributeObj.id}
                    placeholder={attributeObj.placeholder}
                    {...register()}
                    tabIndex={attributeObj.tabIndex}
                />

                {withSpan && <span
                    className={spanClassName}
                    onClick={spanClose}
                ></span>}
            </div>
        </div>
    )
}

export default FormInput;