import { QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: (count, err) =>
                err?.message !== "Request failed with status code 404" &&
                err?.message !== "Request failed with status code 401" &&
                err?.message !== "Unauthenticated" &&
                count < 2,
        },
    },
});

export default queryClient;