
/** 
 * Can be used to check if a waffle tag is enabled.
 * 
 * Usage:
 * const isSomeFlagSet = useWaffleFlag("some-flag");
 * 
 * 
 */

import { useQueryClient } from "@tanstack/react-query";

export const useWaffleFlag = (flag) => {
    const queryClient = useQueryClient();
    const userData = queryClient.getQueryData(['userData']);
  
    return userData?.waffle_flags?.includes(flag) ?? false;
}
