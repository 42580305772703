export const sentryBlacklist = [
    /* Random plugins/extensions */
    "top.GLOBALS",
    /* Stupid Coupert extension */
    "com.coupert.soar",
    "__gCrWeb.learningToolsRuntimeBridge.raiseMessageFromHost",
    "window.ethereum.selectedAddress = undefined",
    "__gCrWeb.translate.handleResponse",
    "zaloJSV2",
    "Can't find variable: __gCrWeb",
    "$ is not defined",
];
