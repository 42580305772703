import React, { useState } from "react";

const AppContext = React.createContext({});

/* The most global context, should only be used for stuff that applies to the entire app! */
const AppContextProvider = ({ children }) => {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [loginForm, setLoginForm] = useState(null);
    const context = {
        isFullScreen,
        setIsFullScreen,
        isLoginOpen,
        setIsLoginOpen,
        loginForm,
        setLoginForm,
    };

    return (
        <AppContext.Provider value={context}>{children}</AppContext.Provider>
    );
};

export { AppContext, AppContextProvider };
