import React, { useState } from "react";

import "../style/pages/notfound/error-page.css";
import { useTranslation } from "react-i18next";
import { getRandomFromArray } from "../core/common/util";
const emojis = ["🥺", "🙈", "😳", "🙊", "😬"];

const onRefreshClicked = (e) => {
    /* Prevent browser redirect */
    e.preventDefault();
    /* Refresh window to reload JS app */
    window.location.reload();
};

export default function ErrorPage() {
    const [pageEmoji] = useState(getRandomFromArray(emojis));
    const { t } = useTranslation("public.error");

    return (
        <div id="content-inner">
            <div className="error-page-wrapper">
                <div className="icon">
                    <span className="text-option active">{pageEmoji}</span>
                </div>

                <div className="text-wrapper">
                    <span className="title">{t("oops")}!</span>
                    <span>
                        {t("something-went-wrong")}.{" "}
                        {t("we-will-look-into-this")}.
                    </span>
                </div>

                <div className="button-wrapper">
                    <button
                        className="button auto-width"
                        onClick={onRefreshClicked}
                    >
                        {t("refresh")}
                    </button>
                </div>
            </div>
        </div>
    );
}
