import { useQuery } from "@tanstack/react-query";
import i18n from "../../../internationalization";
import { minutesToMs } from "../../common/util";
import api, { getTokenFromCookieOrLogout } from "../../services/api";
import TokenService from "../../services/tokenService";
import WebSocketService from "../../services/webSocketService";

const updateUserLanguage = async (language) => {
    if (!language) return;
    try {
        await api.patch("/users/language/", { language });
    } catch (e) {
        console.error(`Failed to PATCH language: ${e}`);
    }
};

export default function useUserData() {
    return useQuery(
        ["userData"],
        async () => {
            const hasToken = !!TokenService.getJwtData();
            if (!hasToken) {
                try {
                    await new Promise(
                        async (resolve, reject) =>
                            await getTokenFromCookieOrLogout(resolve, reject)
                    );
                } catch {
                    return Promise.reject(new Error("Unauthenticated"));
                }
                // TokenService.logout();
                // return null;
            }
            const { data } = await api.get("/users/me/");

            const enableWebSocket = data?.waffle_flags?.includes(
                "enable-react-websocket"
            );

            if (enableWebSocket) {
                WebSocketService.connect();
            }

            const frontendLanguage = i18n?.language?.split("-")?.[0];
            const backendLanguage = data?.platform_language;

            if (!backendLanguage) {
                /* Language in backend not set, send ours */
                updateUserLanguage(frontendLanguage);
            } else if (backendLanguage !== frontendLanguage) {
                /* Language in the backend set and different from ours,
                       switch to the language we got from the backend.
                    */
                i18n.changeLanguage(backendLanguage);
            }

            return data;
        },
        {
            staleTime: minutesToMs(2),
            cacheTime: minutesToMs(5),
        }
    );
}
