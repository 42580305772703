class Handle {
    constructor(handle, hidePopup, container) {
        this.hidePopup = hidePopup;
        if (!handle) return null;
        this.handle = handle;
        this.container = container || handle.closest(".handle-container");
        this.initialise();
    }

    initialise = () => {
        // Interaction values
        this.active = false;
        this.currentTarget = undefined;
        this.currentTouchPosition = null;
        this.currentTranslateY = 0;
        this.postHeight = window.innerHeight - 42;
        this.startTouchPosition = null;
        this.startTranslateY = 0;

        // Set event listeners
        this.ListenTouchEnd();
        this.ListenTouchMove();
        this.ListenTouchStart();
    };

    move = (translateY) => {
        this.container.style.height = `${-1 * translateY - 85}px`;
        this.container.style.transform = `translate3d(0, ${translateY}px, 3px)`;
    };

    setTransform = (offset, end = false) => {
        let translateY = -1 * offset + this.startTranslateY;

        if (Math.abs(translateY) > this.postHeight - 65) {
            translateY = -1 * (this.postHeight - 65);
        }

        if (translateY < 0) {
            translateY = 0;
        }

        if (end) {
            const containerHeight = Math.min(
                this.container.offsetHeight,
                this.postHeight
            );
            const fullVisible =
                this.postHeight - (this.postHeight - containerHeight);

            if (translateY + 42 > fullVisible * 0.65) {
                /* Reset handle position on close */
                this.container.style.height = this.postHeight;
                this.container.style.transform = "translate3d(0, 0px, 3px)";
                return this.hidePopup();
            }
        }

        this.move(translateY);
    };

    TouchEnd = () => {
        this.setTransform(
            this.startTouchPosition - this.currentTouchPosition,
            true
        );

        this.active = false;
        this.currentTouchPosition = null;
        this.startTouchPosition = null;
    };

    // Event listeners
    ListenTouchEnd = () => {
        this.handle.addEventListener("touchend", this.TouchEnd);
    };

    TouchMove = (event) => {
        this.currentTouchPosition = event.changedTouches[0].screenY;
        this.setTransform(this.startTouchPosition - this.currentTouchPosition);
    };

    ListenTouchMove = () => {
        this.handle.addEventListener("touchmove", this.TouchMove, {
            passive: true,
        });
    };

    TouchStart = (event) => {
        this.currentTarget = event.target;
        this.currentTouchPosition = event.changedTouches[0].screenY;
        this.startTouchPosition = event.changedTouches[0].screenY;
        this.startTranslateY =
            parseInt(this.container.style.transform.split(",")[1]) || 0;
    };

    ListenTouchStart = () => {
        this.handle.addEventListener("touchstart", this.TouchStart, {
            passive: true,
        });
    };
}

export default Handle;
