import React from "react";
import LanguageList from "../languageList/LanguageList";
import "./language-selector.css";

function LanguageSelectorMobile({
    selectedLang,
    langListVisible,
    setLangListVisible,
    setSelectedLang,
}) {
    return (
        <div className="language-selector-mobile">
            <div className="language-button-container white mobile">
                <div
                    className="lang-select-button"
                    onClick={() => setLangListVisible((c) => !c)}
                >
                    <div className="lang-name">
                        <div className="global-icon"></div>
                        <div className="language-toggle">{selectedLang}</div>
                        <div
                            className={
                                langListVisible
                                    ? "icon-expand retract"
                                    : "icon-expand"
                            }
                        />
                    </div>
                </div>
            </div>
            {langListVisible && (
                <LanguageList
                    closeLanguageList={() => setLangListVisible(false)}
                    selectedLang={selectedLang}
                    setSelectedLang={setSelectedLang}
                />
            )}
        </div>
    );
}

export default LanguageSelectorMobile;
