import React from "react";
import { ReactComponent as LoadSvg } from "../../../assets/f2f-purple-minimal.svg";

/* Same as LoadingIndicator but without logic for text and translations */

export default function LoadingIndicatorLogo() {
    return (
        <div className="infinite-load-wrapper post-load-wrapper">
            <LoadSvg className="icon" />
        </div>
    );
}
